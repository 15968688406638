import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "../../src/components/Layout"
import BlogPostCard from "../../src/components/BlogPostCard"
import CallToAction from "../../src/components/CallToAction"

import styles from "../styles/blog-list.module.scss"

const BlogPostsList = ({ data, pageContext }) => {
  const previousPageLinkClass = classNames({
    [styles.navigationLink]: true,
    [styles.disabledNavigationLink]: pageContext.humanPageNumber === 1,
  })

  const nextPageLinkClass = classNames({
    [styles.navigationLink]: true,
    [styles.disabledNavigationLink]:
      pageContext.humanPageNumber === pageContext.numberOfPages,
  })

  const renderBlogs = posts => {
    return posts.map((item, index) => {
      const { slug } = item.node.fields
      const { title, date } = item.node.frontmatter
      const { localImage } = item.node

      return (
        <BlogPostCard
          key={`${title}-${index}`}
          image={
            <Img
              fluid={localImage.childImageSharp.fluid}
              alt={title}
              style={{ height: "100%", width: "100%" }}
              imgStyle={{ objectFit: "cover" }}
            />
          }
          title={title}
          description={new Date(date).toLocaleString("pt-PT", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          link={`/${slug}`}
        />
      )
    })
  }

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.title}>
            <h1>Notícias</h1>
            <p>As últimas notícias da nossa freguesia</p>
          </div>
          {/*
            Apparently this is not needed since Gatsby will always break if there's no posts
            https://github.com/gatsbyjs/gatsby/issues/2392#issuecomment-340725116
          */}
          {!!data.allMarkdownRemark.edges.length ? (
            <>
              <div className={styles.postsListsWrapper}>
                {renderBlogs(data.allMarkdownRemark.edges)}
              </div>
              <div className={styles.pagination}>
                <div className={previousPageLinkClass}>
                  <Link
                    // slash in the end solves this warning:
                    // https://github.com/gatsbyjs/gatsby/issues/11243
                    to={pageContext.previousPagePath + "/"}
                  >
                    Anterior
                  </Link>
                </div>
                <div className={nextPageLinkClass}>
                  <Link to={pageContext.nextPagePath + "/"}>Seguinte</Link>
                </div>
              </div>
            </>
          ) : (
            <p>As notícias não estão disponíveis, de momento.</p>
          )}
        </div>
      </div>
      <CallToAction
        title="Necessita de submeter um Requerimento?"
        buttonLabel="Submeter Requerimento"
        url={"/novo-requerimento"}
      />
    </Layout>
  )
}

export default BlogPostsList

export const BlogListTemplateQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog-posts)/.*\\\\.md$/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            title
            date
          }
          localImage {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
